.history-item{
  background-color: white;
  gap: 50px;
  padding: 20px;
}

.history-content-text{
  width: calc(var(--parent-width) - 176px - 30px);
  color: #080C1E;
  font-family: Satoshi-Medium!important;
  font-weight: normal;
}

.history-content{
  width: calc(100% - 20px);
}


.history-item-tools{
  display: flex;
  gap: 16px;
  align-items: center;
  height: 30px;
}
.history-content-date-time{
  font-family: Satoshi-Medium!important;
}
.history-btn{
  padding: 2px 24px!important;
  border-radius: 8px!important;
  color: #5C5E64;
  font-family: Satoshi-Medium!important;
  border: 1px solid #EDF1F9;
  /* font-family: Satoshi-Medium!important; */
}

.history-content-date{
  color: #5C5E64;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  /* justify-content: center; */
}

.history-page{
  height: 600px;
  overflow: scroll;
  border-radius: 0px 0px 8px 8px;
  
}

.score{
  border-radius: 8px;
  width: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  height: 27px;
  font-size: 14px;
  /* font-family: Satoshi-Medium!important; */
}

.danger-score{
  background-color: #F9EDED;
  border: 1px solid #F1C8C8;
  color: #D96473;
}

.success-score{
  background-color: #EEF9ED;
  border: 1px solid #CEF1C8;
  color: #48B266;
}

.warning-score{
  background-color: #FAF2E0;
  border: 1px solid #E5C87C;
  color: #9C7F35;
}

.centered{
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: calc(100vw - 298px); */
}

.history-header{
  font-size: 26px;
  color: #080C1E;
  padding-bottom: 30px;
  padding-top: 18px;
}

button.small{
  padding: 8px;
  width: 48px;
  height: 48px;
}

button.primary{
  background-color: #7C93F7;
  color: white;
}

.history-header-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination-settings__container{
  display: flex;
  align-items: center;
  gap: 10px;
}

.pagination-settings__container>span{
  color: #5C5E64;
  font-family: Satoshi-Medium!important;
}

.pagination__settings{
  width: 15px;
  padding: 10px;
}



.history-header-item-text{
  font-family: Satoshi-Medium!important;
  color: #080C1E;
}

.history-header-color{
  background-color: #F7F9FB;
  border: 1px solid #EDF1F9;
  padding: 12px 48px;
  border-radius: 8px 8px 0px 0px; 
}


.history-header-item-first{
  display: flex;
  gap: 105px;
}

.history-header-item-second{
  display: flex;
  gap: 40px;
}

.history-header-item-text-last{
  padding-right: 95px;
}