.check__container{
    display: flex;
    background-color: #EDF0FF;
    width: 50px;
    padding: 4px;
    height: 24px;
    justify-content: space-between;
    border-radius: 10px;
    cursor: pointer;
    font-family: 'Satoshi-Medium'!important;
}

.check__active{
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background-color: #7C93F7;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.check__disabled{
    background-color: #CACED9;
}