.filedrop__container{
    background-color: #FBFDFF;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    border: 1px solid #EDF1F9;
    padding: 20px;

    transform: translateX(calc(var(--parent-width) / 2 - 248px/2)) translateY(calc(0px - (var(--parent-height) + 149px) / 2));
    width: 248px;
    height: 127px;
}

.sc-dAlyuH{
    display: none!important;
}

.or__container{
    width: 39px;
    height: 170px;
    transform: translateX(calc(var(--parent-width) / 2)) translateY(calc(0px - (var(--parent-height) + 149px) / 2));
    background-color: none;
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    font-family: Satoshi-Regular!important;
    color: #A6A8B0;
}

.poschange__1{
    transform: translateX(170px);
    z-index: 999;
}

.poschange__2{
    transform: translateX(-170px);
    z-index: 999;
}

.filedrop__container1{
    transform: translateX(calc(var(--parent-width) / 2 - 248px/2)) translateY(calc(0px - (var(--parent-height) + 490px) / 2));
}
/* .drop__container{
    position: absolute;
    height: 100px;
} */

.drop__container{
    height: 0px!important;
}

.filedrop__container, textarea, .percentage, .textarea__controll, .textarea, *{
    --parent-width: calc(100vw - 400px - 400px);
    --parent-height: calc(100vh - 200px);
}
.textarea{
    background-color: hsl(0, 0%, 100%);
    width: var(--parent-width);
    height: calc(var(--parent-height));
    padding: 20px;
    border-radius: 16px 0px 4px 4px;
    border: 1px solid #EDF1F9;
    position: relative;
    padding-left: 20px;
    /* border-right: 0px solid rgba(0,0,0,0); */
    /* border-bottom: 0px solid rgba(0,0,0,0); */
}
.textarea__alert-parent{
    border: 1px solid #F1C8C8;
}
.textarea__alert-container{
    height: calc(100% - 60px);
}
textarea{
    background-color: hsl(0, 0%, 100%);
    width: 100%;
    height: calc(100% - 60px);
    color: var(--text-primary-color);
    font-size: 16px;
    font-family: Satoshi-Regular!important;
    border: none;
    outline: none;
    resize: none;
}

.upload__head{
    color: var(--primary-color);
}

.filedrop__content{
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    z-index: 999999;
    border-radius: 10px!important;
}

.tags{
    display: flex;
    gap: 10px;
}


textarea::placeholder{
    color: #C1C2C6;
    font-family: Satoshi-Regular;
    font-size: 16px;
}

.uploader__container{
    display: flex;
}

.percentage{
    height: var(--parent-height);
    background-color: white;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #C1C2C6;
    border: 1px solid #EDF1F9;
    border-radius: 0px 0px 0px 0px;
    width: calc(226px - 40px);
    text-align: center;

}
.percentage>span{
    color: #C1C2C6;
}
.percentage__percent{
    font-size: 48px;
    font-family: "Satoshi-Medium"!important;

}

.percentage__text{
    font-family: Satoshi-Light!important;
    font-size: 16px;
}

.words__count{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}
.textarea__controll{
    width: calc(var(--parent-width) - 30px);
    height: 0px;
    font-family: Satoshi-Light!important;
    display: flex;
    justify-content: space-between;
    align-items: end;
    z-index: 999;
    transform: translateY(-20px) translateX(40px);
}

.words{
    font-family: Satoshi-Light!important;
    color: #5C5E64;
}

.filedrop__container{
    cursor: pointer;
    z-index: 999;
    border-radius: 10px!important;
}

.filedrop__container-active{
    border: 4px var(--primary-color) dashed;
}

.percentage__result{
    font-size: 26px!important;
}

.scan__container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    height: 43px;
    background-color: white;
    border-radius: 0px 0px 0px 12px;
    border: 1px solid #EDF1F9;
    transform: translateY(-5px);
    width: var(--parent-width);
    padding: 0px 20px;
}

.scan-container__content{
    display: flex;
    align-items: center;
    gap: 10px!important;
}

.certificate_footer{
    display: flex;
    justify-content: start;
    align-items: center;
    /*gap: 10px;*/
    height: 43px;
    background-color: white;
    border-radius: 0px 0px 12px 0px;
    border: 1px solid #EDF1F9;
    transform: translateY(1px);
    width: 100%;
    /*width: var(--parent-width);*/
    /*padding: 0px 20px;*/
}

.spin {
    animation: rotateAnimation 2s linear infinite;
    width: 40px;
    height: 40px;
}

@keyframes rotateAnimation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spin__container{
    color: #7C93F7;
}

.spin__container-b{
    display: flex;
    align-items: center;
    gap: 8px;
}
.red-container{
    color: #D96473;
}
.ai-gen__container{
    background-color: #F9EDED;
    border-radius: 8px;
    padding: 10px 32px;
}
.human__container-out{
    background-color: #F2F9F2;
    border-radius: 8px;
    padding: 10px 32px;
}

.human__container{
    color: #48B266;
}

.alert__container{
    display: inline-flex;
    align-items: center;
    gap: 10px;
    padding: 12px;
    background-color: #FFF7F4;
    /* width: 410px; */
    border: 1px solid #F1C8C8;
    border-radius: 12px;
    margin-bottom: 15px;
}

.alert__text{
    color: #7D252F;
    font-size: 14px;
    font-family: Satoshi-Medium;
}

.scan__text{
    font-size: 18px;
    font-family: Satoshi-Bold;
    color: #C1C2C6;
}

.widd{
    padding-left: 20px;
    padding-right: 20px;
}


[contenteditable=true]:empty:not(:focus):before{
    content:"Enter text or upload document to run scan (currenlty only English language supported)";
    color:grey;
    font-style:italic;
}

div{
    outline: none;
}

.fd__button{
    padding: 16px 64px!important;
    width: fit-content!important;
}

.fd__btns{
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: space-between;
}
.fd__btns-checkboxes{
    display: flex;
    gap: 20px;
}

.scan-container__content{
    display: flex;
    align-items: center;
    gap: 5px;
}

.scan-container__content>span{
    font-family: Satoshi-Medium!important;
    font-size: 14px;
}

.s-circle{
    width: 14px;
    height: 14px;
    border-radius: 50%;
}

.ai-gen{
    background-color: rgba(242, 217, 216, 1);
    border: 1px solid rgba(217, 100, 115, 0.75);
}

.human-written{
    background-color: rgba(218, 242, 216, 1);
    border: 1px solid rgba(66, 180, 91, 0.75); 
}

.mixed{
    background-color: rgba(246, 220, 153, 1);
    border: 1px solid rgba(214, 201, 83, 0.75); 
}

.result__container{
    background-color: white;
    width: 400px;
    border: 1px solid rgba(237, 241, 249, 1);
    border-radius: 0px 16px 16px 0px;
    height: calc(var(--parent-height) + 40px);

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    gap: 20px;
}

.result__percent{
    font-size: 32px;
    color: rgba(166, 168, 176, 1);
}

.result__results{
    font-family: Satoshi-Regular!important;
    font-size: 18px;
    color: rgba(166, 168, 176, 1);
}

.result__percent-result{
    color: rgba(8, 12, 30, 1);
    font-size: 32px;
}

.mixed__container{
    background-color: #F6DC99;
    border-radius: 8px;
    padding: 10px 48px;
}

.mixed__container{
    color: #6A4E17;
    font-size: 18px;
}

.speedometr__text{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.speedometr__text>span{
    font-family: Satoshi-Medium!important;
    font-size: 14px;
    color: #A6A8B0;
}

.premium-modal__crown {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background-color: #7C93F7;
    border-radius: 8px;
}

.premium-modal__crown svg {
    width: 24px;
    height: 24px;
}

.premium-modal__header{
    display: flex;
    align-items: center;
    gap: 10px;
}

.premium-modal__title{
    color: #080C1E;
}

.premium-modal__description, .premium-modal__offer{
    color: #5C5E64;
    font-family: Satoshi-Regular!important;
}

.premium-modal__form{
    overflow: visible;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 600px;
    overflow-y: auto;
}

.premium-modal__form::-webkit-scrollbar {
    width: 6px;
}

.premium-modal__form::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
}

.premium-modal__form::-webkit-scrollbar-thumb {
    background: #7C93F7;
    border-radius: 3px;
}

.premium-modal__form::-webkit-scrollbar-thumb:hover {
    background: #6477d1;
}

.premium-modal__actions{
    display: flex;
    gap: 10px;
    padding-top: 30px;
}

.modal-action-button{
    padding: 16px 12px!important;
    width: 260px;
}

.premium-modal__header2{
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
}

.premium-modal__title2{
    font-size: 32px;
    color: #080C1E;
}

.premium-modal__description2{
    font-family: Satoshi-Regular!important;
    font-size: 18px;
    color: #5C5E64;
    text-align: center;
}

.premium-modal__actions2{
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-action-button2{
    padding: 16px 0px!important;
}

.premium-modal__description3{
    text-align: center;
    font-family: Satoshi-Bold!important;
    font-size: 18px;
}

.premium-modal__header3{
    justify-content: center;
}

.premium-modal__error-message {
    color: #ff4d4f;
    font-size: 14px;
    margin-top: 4px;
    margin-bottom: 12px;
}

.premium-modal__input {
  /* height: 40px; */
  font-family: inherit;
  font-size: 14px;
  outline: none;
  margin-bottom: 0px!important;
}

.premium-modal__input:hover,
.premium-modal__textarea:hover {
  border-color: #7C93F7;
  box-shadow: 0 0 0 2px rgba(124, 147, 247, 0.2);
}

.premium-modal__input:focus,
.premium-modal__textarea:focus {
  border-color: #7C93F7;
  box-shadow: 0 0 0 2px rgba(124, 147, 247, 0.2);
}

.premium-modal__textarea {
  width: calc(100% - 28px);
  min-height: 80px;
  height: 150px;
  padding: 8px 12px;
  border-radius: 8px;
  border: 2px solid #EDF1F9;
  resize: none;
  font-family: inherit;
  font-size: 14px;
  transition: all 0.3s ease;
  outline: none;
}

.premium-modal__info {
  display: flex;
  /* align-items: flex-start; */
  gap: 12px;
  padding: 16px;
  background: #EDF0FF;
  border-radius: 12px;
  margin-bottom: 20px;
  border: 1px solid #7C93F7;
  align-items: center;
}

.premium-modal__info-text {
  color: #687EDE;
  font-size: 16px;
  font-family: inherit;
  width: 320px;
  font-size: 14px;
  font-family: Satoshi-Medium!important;
}


.fd__btns-checkboxes-text{
    color: #5C5E64!important;
    font-family: 'Satoshi-Medium'!important;
}

.sections{
    display: flex;
    gap: 20px;
    padding-left: 40px;
    width:100%;
}

.section{
    display: flex;
    gap: 10px;
    color: #7E939A;
    font-family: Satoshi-Medium!important;
    cursor: pointer;
}

.section-active{
    border-bottom: 2px solid #6B84F0;
    color: black!important;
}

.cert-button{
    transform: translateY(-50px);
}

.plagiarism__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

/* BlockLoading component styles */
.block {
    padding: 8px;
    background-color: #F7F9FB;
    border: 1px solid #EDF1F9;
    border-radius: 16px;
    display: flex;
    gap: 12px;
}

.block__title {
    font-family: Satoshi-Medium!important;
    font-size: 16px;
    color: #5C5E64;
    margin-bottom: 8px;
}

.block__content {
    display: flex;
    align-items: center;
    gap: 10px;
}

.block__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 1px solid #EEF0F2;
}

.plagiarism__results{
    color: #080C1E;
    font-family: Satoshi-Regular!important;
    text-align: start;
    width: 100%;
    display: flex;
    gap: 5px;
}

.plagiarism__results-number{
    width: 20px;
    height: 20px;
    background-color: #EEF0F2;
    display: block;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5C5E64;
    font-family: Satoshi-Medium!important;
}


.plagiarism__results-title{
    color: #080C1E;
    font-family: Satoshi-Regular!important;
    text-align: start;
    
}

.plagiarism__results-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    margin-top: 15px;
}

.block-plagiarism{
    padding: 8px;
    background-color: #F7F9FB;
    border: 1px solid #EDF1F9;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 350px;
}

.block-plagiarism__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.subblock{
    padding: 8px;
    background-color: white;
    border: 1px solid #EDF1F9;
    border-radius: 16px;
    display: flex;
    align-items: start;
    gap: 12px;
    width: 320px;
    cursor: pointer;
}

.block-plagiarism__content{
    gap: 12px;
    display: flex;
}

.block__title {
    font-family: Satoshi-Medium!important;
    font-size: 14px;
    letter-spacing: 0%;
}

.block__link{
    font-family: Satoshi-Regular!important;
    font-size: 14px;
    letter-spacing: 0%;
    color: #5C5E64!important;
}

.see-all{
    font-family: Satoshi-Regular!important;
    font-size: 14px;
    letter-spacing: 0%;
    color: #6B84F0!important;
    cursor: pointer;
}

.blocks-list{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
}