.progress-bar__container{
    height: 8px;
    background-color: #EEF0F2;
    border-radius: 50px;
    min-width: 150px;
}

.progress-bar__content{
    height: 8px;
    border-radius: 50px;
}