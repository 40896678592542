.tooltip__child{
    display: none;
}

.tooltip__parent{
    font-family: Satoshi-Light!important;
    margin-right: 3px;
}

.bad__tooltip{
    background-image: url(Tooltip.png);
}

.good__tooltip{
    background-image: url(Tooltip_green.png);
}

.medium__tooltip{
    background-image: url(Tooltip_yellow.png);
}

.tooltip__parent-red{
    background-color: #F9EDED;
}

.tooltip__parent-green{
    background-color: #f2f9f2;
}

.tooltip__parent-yellow{
    background-color: #F5E7BE;
}

.segmentation-tooltip{
    z-index: 100000000;
}

.div__tooltip{
    position: relative;
    width: 0px;
    height: 0px;
    display: inline;
}

/* New classes for plagiarism */
.plagiarism-high {
    border-bottom: 2px solid #7D0404;
}

.plagiarism-medium {
    border-bottom: 2px solid #8B6E0B;
}

.plagiarism-low {
    border-bottom: 2px solid #1E5631;
}

/* Custom tooltip styling for plagiarism */
.plagiarism-tooltip {
    background-color: white;
    border: 1px solid #EDF1F9;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 6px 8px;
    font-size: 12px;
    line-height: 1.2;
    text-align: center;
    color: #080C1E;
}

.plagiarism-tooltip small {
    font-size: 10px;
    opacity: 0.8;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
}

/* Add styling for the plagiarism view toggle button */
.plagiarism-view-toggle {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    width: 100%;
}

.plagiarism-highlighted-text {
    margin-top: 20px;
    border-radius: 10px;
    padding: 10px;
    background-color: #F7F9FB;
    border: 1px solid #EDF1F9;
}

.selected__sentence {
    background-color: #7C93F7;
    color: white;
}